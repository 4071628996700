@import url('../../App.css');
.header-container {
    display: flex;
    align-items: center !important;
    flex-direction: column;
    background-color: rgb(39, 32, 32);
    height: 80px;
    justify-content: center;
}

.header-container .row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search-box {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
}

.search-box input {
    /* border: 2px solid var(--primary-color) !important; */
    height: 40px;
    width: 80%;
    border-radius: 5px;
    padding: 10px;
    outline: none !important;
    border: none !important;
}

.search-btn {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    border: none;
    background-color: var(--primary-color);
    color: var(--secondary-color);
}

.brand-txt {
    margin-bottom: 0 !important;
}

.brand-txt a {
    font-size: 40px;
    text-decoration: none;
    color: var(--primary-color) !important;
}

.navbar-nav a {
    color: var(--secondary-color) !important;
    text-decoration: none !important;
    font-size: 16px !important;
    padding: 10px;
}

.navbar-nav a:hover {
    color: var(--primary-color) !important;
}

.user-action .user-login {
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
    border-radius: 5px;
    padding: 10px 25px;
    font-weight: bold;
    width: 250px;
    height: 40px;
    background-color: transparent !important;
    font-family: popines;
}

.user-action .user-login:hover {
    color: var(--secondary-color);
    background-color: var(--primary-color) !important;
}