.login-container {
    /* width: 100vw; */
    height: 100vh;
    display: flex;
    justify-items: center;
    /* align-items: center; */
    flex: 1;
    margin-top: 5rem;
}

.login-container .login {
    width: 100%;
    display: flex;
    /* height: 400px; */
    column-gap: 10px;
    row-gap: 10px;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.inner-container {
    display: flex;
    flex-direction: column;
    /* border: 2px solid var(--primary-color); */
    height: 330px;
    width: 35%;
    border-radius: 10px;
    padding: 10px;
    align-items: center;
    row-gap: 10px;
    background-color: black;
}

.inner-container .user-input {
    width: 60%;
    border: 2px solid var(--primary-color);
    padding: 5px;
    background-color: transparent;
    border-radius: 10px;
    height: 40px;
}

.inner-container .headings {
    color: var(--primary-color);
    font-size: 24px;
    font-weight: bold;
    font-family: poppines;
}

.inner-container span {
    display: flex;
    column-gap: 5px;
    color: var(--primary-color);
    align-items: center;
}

.inner-container input[type="checkbox"] {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
    width: 20px;
    height: 20px;
}

::selection {
    background-color: var(--primary-color) !important;
}

.login-btn {
    width: 100px;
    height: 40px;
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
    border-radius: 10px;
    font-weight: 20px;
    background-color: transparent !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-btn:hover {
    background-color: var(--primary-color) !important;
    color: var(--secondary-color);
}

.go-to-signup {
    color: var(--primary-color);
    text-transform: uppercase;
    text-decoration: underline;
    margin-top: 20px;
    font-size: 20px;
}

.go-to-signup:hover {
    color: var(--primary-color);
}