.carousel-caption h3,
.carousel-caption p {
    color: var(--primary-color) !important;
}

.carousel-indicators button {
    background-color: var(--primary-color) !important;
    width: 15px !important;
    height: 15px !important;
    border-radius: 50% !important;
}

.carousel-indicators .active {
    color: var(--secondary-color);
}

.carousel-control-prev .carousel-control-prev-icon,
.carousel-control-next .carousel-control-next-icon {
    border: 2px solid var(--primary-color);
    border-radius: 5px;
    width: 40px;
    height: 40px;
    color: var(--primary-color);
}