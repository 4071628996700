.navbar {
    width: 100%;
    background-color: var(--primary-color);
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--secondary-color);
    padding: 0;
}

.navbar .link {
    color: var(--secondary-color);
    padding: 0 10px;
    font-weight: bold;
    text-decoration: none;
    height: inherit;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar .link:hover {
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

.navbar .link:active {
    background-color: var(--secondary-color);
    color: var(--primary-color);
}